import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Process = makeShortcode("Process");
const Box = makeShortcode("Box");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "the-kernel-gaming-track",
      "style": {
        "position": "relative"
      }
    }}>{`The Kernel Gaming Track`}<a parentName="h1" {...{
        "href": "#the-kernel-gaming-track",
        "aria-label": "the kernel gaming track permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <h2 {...{
      "id": "-week-0---brainstorm",
      "style": {
        "position": "relative"
      }
    }}>{`💡 Week 0 - Brainstorm`}<a parentName="h2" {...{
        "href": "#-week-0---brainstorm",
        "aria-label": " week 0   brainstorm permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p><img parentName="p" {...{
        "src": "/images/gaming-img/week-0-syllabus-brianstorm.png",
        "alt": "Week 0 Syllabus"
      }}></img></p>
    <p>{`In Week 0 of the `}<strong parentName="p">{`Kernel Genesis Block`}</strong>{`, the participants of the Gaming Guild started mapping out topics that we'll both learn and build on during the 8 week program. This is a draft syllabus based on the brainstorm. `}</p>
    <p>{`We envision this being a participatory community where different guild members sign up to learn about and then present on topics that interest them. Below is the format of what you need to do when you sign up for a topic:`}</p>
    <h3 {...{
      "id": "format",
      "style": {
        "position": "relative"
      }
    }}>{`Format`}<a parentName="h3" {...{
        "href": "#format",
        "aria-label": "format permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <Process mdxType="Process">
      <p>{`Spend time learning about your topic.`}</p>
      <p>{`Document things to share with the group. This can be as simple as putting links and a few sentences describing the links in a google doc.`}</p>
      <Box mdxType="Box">
        <p>{`3-5 days before our meeting send to the group your documentation (optional for the group to read) and 15 minutes of media to prepare them for your discussion. For example:`}</p>
        <ul>
          <li parentName="ul">{`A medium post`}</li>
          <li parentName="ul">{`A video`}</li>
          <li parentName="ul">{`A game to play`}</li>
        </ul>
      </Box>
      <Box mdxType="Box">
        <p>{`Be prepared to give a short presentation on the topic or lead a participatory activity.`}</p>
        <ul>
          <li parentName="ul">{`Power Point`}</li>
          <li parentName="ul">{`Brainstorm`}</li>
        </ul>
      </Box>
      <p>{`Be prepared with questions to lead a discussion.`}</p>
    </Process>
    <hr></hr>
    <h2 {...{
      "id": "week-1---project-pitches",
      "style": {
        "position": "relative"
      }
    }}>{`Week 1 - Project Pitches`}<a parentName="h2" {...{
        "href": "#week-1---project-pitches",
        "aria-label": "week 1   project pitches permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`The first week of the guild had the members pitch their projects and work on their Substack Newsletters. These can be found in the `}<a parentName="p" {...{
        "href": "/track-gaming/genesis/curveball"
      }}>{`⚾ Curveball Section`}</a></p>
    <h2 {...{
      "id": "week-2---game-design-and-player-motivation",
      "style": {
        "position": "relative"
      }
    }}>{`Week 2 - Game Design and Player Motivation`}<a parentName="h2" {...{
        "href": "#week-2---game-design-and-player-motivation",
        "aria-label": "week 2   game design and player motivation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`H/T Paul Gadi`}</p>
    <h4 {...{
      "id": "crafted-reading",
      "style": {
        "position": "relative"
      }
    }}>{`Crafted Reading`}<a parentName="h4" {...{
        "href": "#crafted-reading",
        "aria-label": "crafted reading permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <List mdxType="List">
      <Link to="https://www.projecthorseshoe.com/reports/featured/ph19r7.htm" mdxType="Link">
        <p>{`Group Report: Prosocial economics for game design`}</p>
      </Link>
      <Link to="https://www.gamasutra.com/view/feature/1524/the_chemistry_of_game_design.php?print=1" mdxType="Link">
        <p>{`The Chemistry of Game Design`}</p>
      </Link>
      <Link to="https://www.gdcvault.com/play/1023329/Engines-of-Play-How-Player" mdxType="Link">
        <p>{`Engines of Play: How Player Motivation Changes Over Time`}</p>
      </Link>
    </List>
    <h4 {...{
      "id": "curated-material",
      "style": {
        "position": "relative"
      }
    }}>{`Curated Material`}<a parentName="h4" {...{
        "href": "#curated-material",
        "aria-label": "curated material permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <List mdxType="List">
      <Link to="https://www.deconstructoroffun.com/" mdxType="Link">
        <p>{`Deconstructor of Fun`}</p>
      </Link>
      <Link to="https://lostgarden.home.blog/" mdxType="Link">
        <p>{`Lost Garden`}</p>
      </Link>
      <Link to="https://gamediscoverability.substack.com/" mdxType="Link">
        <p>{`Game Discoverability NOW!`}</p>
      </Link>
    </List>
    <h2 {...{
      "id": "week-3---chainlink-oracles--bringing-free-to-play-players-to-blockchain-games",
      "style": {
        "position": "relative"
      }
    }}>{`Week 3 - Chainlink Oracles + Bringing Free-to-Play Players to Blockchain Games`}<a parentName="h2" {...{
        "href": "#week-3---chainlink-oracles--bringing-free-to-play-players-to-blockchain-games",
        "aria-label": "week 3   chainlink oracles  bringing free to play players to blockchain games permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <h3 {...{
      "id": "chainlink-oracles",
      "style": {
        "position": "relative"
      }
    }}>{`Chainlink Oracles`}<a parentName="h3" {...{
        "href": "#chainlink-oracles",
        "aria-label": "chainlink oracles permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`H/T Leon Prouger`}</p>
    <h4 {...{
      "id": "crafted-reading-1",
      "style": {
        "position": "relative"
      }
    }}>{`Crafted Reading`}<a parentName="h4" {...{
        "href": "#crafted-reading-1",
        "aria-label": "crafted reading 1 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <List mdxType="List">
      <Link to="https://docs.google.com/presentation/d/1adZXo4RVS2A4CtrU-8gJhV4SDn3Z26jDliJIPlUHwic/edit?usp=sharing" mdxType="Link">
        <p>{`Gaming and Ethereum: Connecting Off-Chain Games to Ethereum with Chainlink`}</p>
      </Link>
    </List>
    <h4 {...{
      "id": "curated-material-1",
      "style": {
        "position": "relative"
      }
    }}>{`Curated Material`}<a parentName="h4" {...{
        "href": "#curated-material-1",
        "aria-label": "curated material 1 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <List mdxType="List">
      <Link to="https://blog.chain.link/create-dynamic-nfts-using-chainlink-oracles/" mdxType="Link">
        <p>{`Create Dynamic NFTs using Chainlink Oracles`}</p>
      </Link>
      <Link to="https://chain.link/presentations/devcon5.pdf" mdxType="Link">
        <p>{`Devcon 5 Chainlink Presentation`}</p>
      </Link>
      <Link to="https://medium.com/genie-platform/meet-the-genie-6bb60fe67943" mdxType="Link">
        <p>{`Genie Platform: Meet The Genie`}</p>
      </Link>
    </List>
    <h2 {...{
      "id": "week-4---bringing-free-to-play-players-to-blockchain-games",
      "style": {
        "position": "relative"
      }
    }}>{`Week 4 - Bringing Free-to-Play Players to Blockchain Games`}<a parentName="h2" {...{
        "href": "#week-4---bringing-free-to-play-players-to-blockchain-games",
        "aria-label": "week 4   bringing free to play players to blockchain games permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`H/T Wade Kimbrough`}</p>
    <h4 {...{
      "id": "crafted-reading-2",
      "style": {
        "position": "relative"
      }
    }}>{`Crafted Reading`}<a parentName="h4" {...{
        "href": "#crafted-reading-2",
        "aria-label": "crafted reading 2 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <List mdxType="List">
      <Link to="https://medium.com/@billyrennekamp/re-fungible-token-rft-297003592769" mdxType="Link">
        <p>{`Re-Fungible Tokens: What happens when a Bonded Token owns an NFT?`}</p>
      </Link>
      <Link to="https://books.google.com.ph/books/about/The_Grasshopper.html?id=G9z4wjVB_0wC&redir_esc=y" mdxType="Link">
        <p>{`The Grasshopper: Games, Life and Utopia`}</p>
      </Link>
    </List>
    <h2 {...{
      "id": "week-5---nfts-for-humanitarian-outcomes-and-community-inclusion-currencies",
      "style": {
        "position": "relative"
      }
    }}>{`Week 5 - NFTs for Humanitarian Outcomes and Community Inclusion Currencies`}<a parentName="h2" {...{
        "href": "#week-5---nfts-for-humanitarian-outcomes-and-community-inclusion-currencies",
        "aria-label": "week 5   nfts for humanitarian outcomes and community inclusion currencies permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`H/T Wade Kimbrough`}</p>
    <h4 {...{
      "id": "crafted-reading-3",
      "style": {
        "position": "relative"
      }
    }}>{`Crafted Reading`}<a parentName="h4" {...{
        "href": "#crafted-reading-3",
        "aria-label": "crafted reading 3 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <List mdxType="List">
      <Link to="https://dashboard.sarafu.network/" mdxType="Link">
        <p>{`Example CIC Dashboard`}</p>
      </Link>
      <Link to="https://gitlab.com/grassrootseconomics/cic-modeling" mdxType="Link">
        <p>{`CIC Modeling Repo`}</p>
      </Link>
    </List>
    <h2 {...{
      "id": "week-6---avoiding-gas-fees-via-sidechains",
      "style": {
        "position": "relative"
      }
    }}>{`Week 6 - Avoiding Gas Fees via Sidechains`}<a parentName="h2" {...{
        "href": "#week-6---avoiding-gas-fees-via-sidechains",
        "aria-label": "week 6   avoiding gas fees via sidechains permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <List mdxType="List">
      <Link to="https://medium.com/@austin_48503/nifty-ink-an-ethereum-tutorial-c860a4904cb2" mdxType="Link">
        <p>{`Nifty Ink and xDai to Mainnet`}</p>
      </Link>
    </List>
    <hr></hr>
    <h2 {...{
      "id": "topic-backlog",
      "style": {
        "position": "relative"
      }
    }}>{`Topic Backlog`}<a parentName="h2" {...{
        "href": "#topic-backlog",
        "aria-label": "topic backlog permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <h4 {...{
      "id": "the-technology",
      "style": {
        "position": "relative"
      }
    }}>{`The Technology`}<a parentName="h4" {...{
        "href": "#the-technology",
        "aria-label": "the technology permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <ul>
      <li parentName="ul">{`NFTs, ERC-1155s`}</li>
      <li parentName="ul">{`State Machines`}</li>
      <li parentName="ul">{`Dynamic NFTs`}</li>
      <li parentName="ul">{`Re-Fungible Tokens`}</li>
    </ul>
    <h4 {...{
      "id": "moving-beyond-nfts",
      "style": {
        "position": "relative"
      }
    }}>{`Moving Beyond NFTs`}<a parentName="h4" {...{
        "href": "#moving-beyond-nfts",
        "aria-label": "moving beyond nfts permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <ul>
      <li parentName="ul">{`What other affordances does blockchain bring games?`}</li>
      <li parentName="ul">{`What kinds of mechanics can we only do on blockchain?`}</li>
      <li parentName="ul">{`Crypto-economic primitives (e.g. refungible tokens)`}</li>
      <li parentName="ul">{`New Monetization Models`}</li>
    </ul>
    <h4 {...{
      "id": "learning-games",
      "style": {
        "position": "relative"
      }
    }}>{`Learning Games`}<a parentName="h4" {...{
        "href": "#learning-games",
        "aria-label": "learning games permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <ul>
      <li parentName="ul">{`Through play users learn about blockchain`}</li>
      <li parentName="ul">{`Through play users learn about smart contracts`}</li>
    </ul>
    <h4 {...{
      "id": "network-effect-and-games",
      "style": {
        "position": "relative"
      }
    }}>{`Network Effect and Games`}<a parentName="h4" {...{
        "href": "#network-effect-and-games",
        "aria-label": "network effect and games permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <ul>
      <li parentName="ul">{`How To Bring in new players`}</li>
      <li parentName="ul">{`How To Bring in new developers`}</li>
      <li parentName="ul">{`How to build more prosocial web3 games`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      